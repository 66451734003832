import Banner from "@/components/Cliente/Banner/Banner.vue";

export default {
	name: "Noticia",
	components: {
		Banner
	},
	data() {
		return {
			blLoading: true,
			arBreadcrumbs: [{
					text: "Início",
					to: "/"
				},
				{
					text: "Notícias",
					to: "/noticias"
				},
				{
					text: "Notícia"
				}
			],
			obNoticia: null
		};
	},
	created() {
		this.obter();
	},
	methods: {
		obter() {
			this.blLoading = true;

			if (this.$route.params.rascunho) {
				this.$root.$api
					.get(
						"noticia/obterPorSlugComRascunho/" +
						this.$route.params.slug
					)
					.then(response => {
						this.obNoticia = response.retorno;
						this.blLoading = false;
					});
			} else {
				this.$root.$api
					.get("noticia/obterPorSlug/" + this.$route.params.slug)
					.then(response => {
						this.obNoticia = response.retorno;
						this.blLoading = false;

						this.arBreadcrumbs[2].text = this.obNoticia.titulo;
					});
			}
		}
	}
};